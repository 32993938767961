#navbarMain {
  background-color: #000000;
  -webkit-box-shadow: 0 0 25px 25px #000000;
  box-shadow: 0 0 11px 7px #000000;
  transition: 1s;
  width: 100%;
  position: static;
  z-index: 999;
}

#imgLogo {
  height: 65px;
  width: auto;
}

#navItem {
  width: 155px;
  display: flex;
  justify-content: center;
  color: #FFDC38;
  font-size: 17px;
  font-weight: bold;
  font-family: 'Source Code Pro', monospace;
  transition: 0.25s;
}

#navItem:hover {
  color: #5b5b5c;
}

#contMint {

  background: url("./assets/gfx/background/graphics_shapes.png") no-repeat top left padding-box,
  linear-gradient(#000000, #000000) padding-box,
  repeating-linear-gradient(45deg, #f4b366, #3b1700, #f4b366, #3b1700, #f4b366) border-box;

  border: 12px solid transparent;
  background-size: cover;
  border-radius: 1px;

  display: flex;
  align-items: center;
  scroll-margin: 127px;
  z-index: 1;
  height: 100%;

  min-height: 780px;
}

@media only screen and (max-width: 400px) {
  #contMint {
    min-height: 660px;
  }
}

#colMint {
  display: flex;
  align-items: center;
}

#videoDAOVIP {
  max-width: 100%;
  margin: 10px auto;
  height: auto;
}

#connectButton {
  background-color: #FFDC38;
  border-color: #FFDC38;
  cursor: pointer;
}

#divFrame {
  width: 111px;
  height: 110px;
  background: linear-gradient(#000000, #000000) padding-box,
  repeating-linear-gradient(45deg, #fca454, #3b1700, #fca454, #3b1700, #fca454) border-box;
  border: 5px solid transparent;
  border-radius: 1px;
}

#txtAmount {
  position: relative;
  width: 101px;
  height: 101px;
  border: none;
  text-align: center;
  font-size: 40px;
}

#imgControls {
  margin-left: 35px;
  margin-right: 35px;
  padding: 0;
  cursor: pointer;
}

#btnMint {
  border: none;
  margin-top: 10px;
  cursor: pointer;
}

#btnMint:active {
  margin-top: 0;
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-right: 1px solid orange;
  border-bottom: 1px solid orange;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.info-text {
  color: #FFF;
  text-shadow: 2px 0 1px #000,
  0 2px 1px #000,
  -2px 0 1px #000,
  0 -2px 1px #000,
  2px 2px 1px #000,
  -2px -2px 1px #000,
  2px -2px 1px #000,
  -2px 2px 1px #000;
}
