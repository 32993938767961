.social-links {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  &, a, a:visited {
    color: #fca454;
    text-shadow:
            2px 0 1px #000,
            0 2px 1px #000,
            -2px 0 1px #000,
            0 -2px 1px #000,
            2px 2px 1px #000,
            -2px -2px 1px #000,
            2px -2px 1px #000,
            -2px 2px 1px #000;

    scale: 1;
    transition: all ease-in-out 0.2s;
  }
  a:hover {
    color: #FFDC38;
    scale: 1.2;
  }

  svg {
    filter:
            drop-shadow(1px 1px 1px #000)
            drop-shadow(1px 0 1px #000)
            drop-shadow(0 1px 1px #000)
            drop-shadow(-1px 0 1px #000)
            drop-shadow(0 -1px 1px #000)
            drop-shadow(1px 1px 1px #000)
            drop-shadow(-1px -1px 1px #000)
            drop-shadow(1px -1px 1px #000)
            drop-shadow(-1px 1px 1px #000);
  }
}
