
.web3modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background: rgba(0,0,0,0.5);

    *:focus-visible {
        outline: none;
    }

    .web3modal {
        display: flex;
        background: linear-gradient(#000000, #000000) padding-box,
        repeating-linear-gradient(45deg, #fca454, #3b1700, #fca454, #3b1700, #fca454)  border-box;
        border: 5px solid transparent;
        border-radius: 4px;
        max-width: 798px;

        .provider-btn {
            display: flex;
            flex-direction: column;
            height: 150px;
            color: #FFF;
            text-decoration: none;
            text-align: center;
            padding: 20px;

            border-right: 1px solid rgba(252, 164, 84, 0.66);
            border-bottom: 1px solid rgba(252, 164, 84, 0.66);
            box-sizing: border-box;

            a, a:visited {
                color: #FFF;
                text-decoration: none;
                &:hover {
                    color: #fca454
                }
                transition: all 0.5s;
            }

            .provider-logo {
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                img {
                    max-width: 50px;
                    max-height: 50px;
                }
            }
            .provider-title {}
            .provider-text {
                font-size: 13px;
            }
        }

    }
}
